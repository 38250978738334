import { _, Backbone } from "legacy-js/vendor";
import { getStaticUrl } from "legacy-js/config";
import { app } from "js/namespaces";
import { StyleSheet } from "js/core/styleSheet";
import {
    PaintStyle,
    ShapeType,
    ShapeColorStyle,
    IconStyle,
    ThemeStyleWeight,
    PaletteColorType,
    PREDEFINED_PALETTES
} from "legacy-common/constants";
import { formatter } from "js/core/utilities/formatter";
import ReferenceCollection from "js/core/storage/referenceCollection";
import StorageModel from "js/core/storage/storageModel";
import { themes, THEME_DEFAULTS } from "legacy-common/themes";
import { builtInFonts } from "legacy-common/fontConstants";
import { Palette } from "legacy-js/core/models/palette";

export function getThemeUrl(path, style, version) {
    const url = path ? `/legacy-themes/v${version}/${path}/${style}.scss` : `/legacy-themes/v${version}/${style}.scss`;
    return getStaticUrl(url);
}

const Theme = StorageModel.extend({

    root: "user_themes",

    defaults: THEME_DEFAULTS,

    hasMigrationChanges: false,

    getFontIds: function() {
        return Object.entries(this.attributes)
            .filter(([key, value]) => key.endsWith("FontId"))
            .map(([key, value]) => value);
    },

    getColors: function() {
        let themeColors = this.get("colors");
        if (!themeColors) {
            let palette = this.getPalette(this.get("palette_name"));
            themeColors = _.clone(palette.colors);
        }
        return themeColors;
    },

    getAccentColorsCount() {
        return Object.keys(this.palette.getAccentColors()).length;
    },

    getPalette: function(paletteId) {
        let palette = _.find(PREDEFINED_PALETTES, { id: paletteId });
        if (!palette) {
            // fallback for old theme support
            palette = _.find(PREDEFINED_PALETTES, { old_id: paletteId });
        }
        if (!palette) {
            palette = _.find(PREDEFINED_PALETTES, { id: "colorful" });
        }
        return _.clone(palette);
    },

    isBespoke: function() {
        return this.has("styleSheet");
    },

    getStyles(version, reloadCache = false) {
        if (!this.getStylesPromises) {
            this.getStylesPromises = {};
        }

        if (reloadCache) {
            delete this.getStylesPromises[version];
        }

        if (!this.getStylesPromises[version]) {
            const getVersionedThemeUrl = (path, style) => getThemeUrl(path, style, version);

            this.getStylesPromises[version] = (async () => {
                const decorationStyles = {
                    outlined: await this.loadStyleSheet(getVersionedThemeUrl("elements", "outlined")),
                    muted: await this.loadStyleSheet(getVersionedThemeUrl("elements", "muted")),
                    filled: await this.loadStyleSheet(getVersionedThemeUrl("elements", "filled")),
                    fillAndStroke: await this.loadStyleSheet(getVersionedThemeUrl("elements", "fillAndStroke")),
                };

                ////// Load frankenstein stylesheet from theme properties //////
                const styleSheet = await this.loadStyleSheet(getVersionedThemeUrl(null, "default"));

                this.loadStyleSheet(getVersionedThemeUrl("backgrounds", this.get("styleBackground")), styleSheet);

                const decoration = this.get("styleDecoration");
                await this.loadStyleSheet(getVersionedThemeUrl("decorations", decoration), styleSheet);

                await this.loadStyleSheet(getVersionedThemeUrl("elements", this.get("styleElementStyle")), styleSheet);

                if (this.get("styleShape") == ShapeType.NONE) {
                    await this.loadStyleSheet(getVersionedThemeUrl("shapes", "shape-none"), styleSheet);
                } else {
                    await this.loadStyleSheet(getVersionedThemeUrl("shapes", "shape"), styleSheet);
                }

                await this.loadStyleSheet(getVersionedThemeUrl("weights", this.get("styleWeight")), styleSheet);

                await this.loadFonts(styleSheet);

                await this.loadStyleSheet(getVersionedThemeUrl("effects", this.get("styleEffect")), styleSheet);

                if (this.has("customStyles")) {
                    await this.mergeCssIntoStylesheet(styleSheet, this.get("customStyles"));
                }

                styleSheet.applyStyleSheetVariables(styleSheet.variables);
                styleSheet.processFillAndStroke();
                //////

                // Apply any variables to the values in the decorationStyles
                for (const style of Object.keys(decorationStyles)) {
                    const decorationStyle = decorationStyles[style];
                    for (const property of Object.keys(decorationStyle)) {
                        const value = decorationStyle[property];
                        if (value && typeof (value) == "string" && value.startsWith("$")) {
                            const varName = value.split(" ")[0];
                            decorationStyle[property] = decorationStyle[property].replace(varName, styleSheet.variables[varName.substr(1)]);
                        }
                    }
                }

                return { styleSheet, decorationStyles };
            })();
        }

        return this.getStylesPromises[version];
    },

    async loadTheme() {
        if (!this.has("colors")) {
            let predefinedPalette = _.find(PREDEFINED_PALETTES, { id: this.get("palette_name") || "colorful" });
            if (predefinedPalette) {
                this.set("colors", predefinedPalette.colors);
            }
        }

        this.palette = new Palette(this);

        await this.migrate();
    },

    async migrate() {
        //// February 2018 migration to split styleBackground options into defaultBackgroundColor ////
        const styleBackground = this.get("styleBackground");
        const defaultBackgroundColor = this.get("defaultBackgroundColor");
        const removedStyleBackgrounds = {
            "light": PaletteColorType.BACKGROUND_LIGHT,
            "dark": PaletteColorType.BACKGROUND_DARK,
            "color": PaletteColorType.THEME
        };

        if (["gradient", "reverseGradient"].includes(styleBackground) && !defaultBackgroundColor) {
            this.set("defaultBackgroundColor", "theme");
            this.hasMigrationChanges = true;
        } else if (removedStyleBackgrounds[styleBackground]) {
            let defaultBackground = removedStyleBackgrounds[styleBackground];
            this.set({
                "defaultBackgroundColor": defaultBackground,
                "styleBackground": "none"
            });
            this.hasMigrationChanges = true;
        }

        //// FONTS MIGRATION OLDEST -> PREVIOUS ////
        if (!this.get("fontHeaderFontId") && (!this.get("fontHeader") || !this.get("fontHeaderRegular"))) {
            const headerFontPath = `${this.get("styleTitleFont")}-header-${this.get("styleFontWeight")}`;
            const styleSheet = await this.loadStyleSheet(getThemeUrl("fonts", headerFontPath, 9));
            this.setObsoleteFontVariables("header", headerFontPath, styleSheet.variables);
        }
        if (!this.get("fontTitleFontId") && (!this.get("fontTitle") || !this.get("fontTitleRegular"))) {
            let titleFont = this.get("styleTitleFont");
            // special case for existing montserrat themes which actually use playfair as title font
            if (titleFont === "playfair") {
                titleFont = "raleway";
            }
            const titleFontPath = `${titleFont}-title-${this.get("styleFontWeight")}`;
            const styleSheet = await this.loadStyleSheet(getThemeUrl("fonts", titleFontPath, 9));
            this.setObsoleteFontVariables("title", titleFontPath, styleSheet.variables);
        }
        if (!this.get("fontBodyFontId") && (!this.get("fontBody") || !this.get("fontBodyRegular"))) {
            const bodyFontPath = `${this.get("styleBodyFont")}-body`;
            const styleSheet = await this.loadStyleSheet(getThemeUrl("fonts", bodyFontPath, 9));
            this.setObsoleteFontVariables("body", bodyFontPath, styleSheet.variables);
        }

        //// FONTS MIGRATION PREVIOUS -> CURRENT ////
        for (const style of ["Header", "Title", "Body"]) {
            if (!this.get(`font${style}FontId`)) {
                // Marking so we have changes first
                this.hasMigrationChanges = true;

                const regularFontFaceName = this.get(`font${style}Regular`);
                const emphasizedFontFaceName = this.get(`font${style}Emphasized`);

                // Looking up built in font
                for (const [fontId, fontDefinition] of Object.entries(builtInFonts)) {
                    for (const fontStyle of fontDefinition.styles) {
                        if (fontStyle.fontFaceName === regularFontFaceName) {
                            this.set({
                                [`font${style}FontId`]: fontId,
                                [`font${style}Weight`]: fontStyle.weight
                            });
                        }

                        if (fontStyle.fontFaceName === emphasizedFontFaceName) {
                            this.set({
                                [`font${style}BoldWeight`]: fontStyle.weight
                            });
                        }
                    }
                }

                // Custom font
                if (!this.get(`font${style}FontId`)) {
                    const [fontId, fontStyle] = regularFontFaceName.split("/");
                    const emphasizedFontStyle = emphasizedFontFaceName.split("/")[1];
                    this.set({
                        [`font${style}FontId`]: fontId,
                        [`font${style}Weight`]: fontStyle === "bold" ? 700 : 400,
                        [`font${style}BoldWeight`]: emphasizedFontStyle === "bold" ? 700 : 400
                    });
                }

                // Cleaning up obsolete fields
                this.set({
                    [`font${style}`]: null,
                    [`font${style}Regular`]: null,
                    [`font${style}Italic`]: null,
                    [`font${style}Emphasized`]: null,
                    [`font${style}EmphasizedItalic`]: null
                });
            }
        }
    },

    async loadFonts(styleSheet) {
        // Composing style sheets
        styleSheet = this.mergeCssIntoStylesheet(styleSheet, await this.buildCustomFontCss("header", "Header"));
        styleSheet = this.mergeCssIntoStylesheet(styleSheet, await this.buildCustomFontCss("display", "Header"));
        styleSheet = this.mergeCssIntoStylesheet(styleSheet, await this.buildCustomFontCss("title", "Title"));
        styleSheet = this.mergeCssIntoStylesheet(styleSheet, await this.buildCustomFontCss("body", "Body"));
        return styleSheet;
    },

    /**
     * Sets obsolete font variables for migration
     */
    setObsoleteFontVariables(fontType, fontFamily, variables) {
        const capitalizedFontType = formatter.capitalizeFirstLetter(fontType);
        const fontUpdates = {
            [`font${capitalizedFontType}`]: fontFamily,
            [`font${capitalizedFontType}Regular`]: variables[`${fontType}FontRegular`],
            [`font${capitalizedFontType}Italic`]: variables[`${fontType}FontItalic`],
            [`font${capitalizedFontType}Emphasized`]: variables[`${fontType}FontEmphasized`],
            [`font${capitalizedFontType}EmphasizedItalic`]: variables[`${fontType}FontEmphasizedItalic`],
            [`font${capitalizedFontType}LineHeight`]: variables[`${fontType}FontLineHeight`],
            [`font${capitalizedFontType}LetterSpacing`]: variables[`${fontType}FontLetterSpacing`],
            [`font${capitalizedFontType}TextTransform`]: variables[`${fontType}FontTextTransform`] || "auto",
            [`font${capitalizedFontType}Scaling`]: variables[`${fontType}FontScaling`] || 100
        };

        if (variables[`${fontType}FontStrokeThickness`]) {
            fontUpdates[`font${capitalizedFontType}StrokeThickness`] = variables[`${fontType}FontStrokeThickness`];
        }
        this.set(fontUpdates);
    },

    async buildCustomFontCss(fontKey, textStyle) {
        return `
        $${fontKey}FontId: ${this.get(`font${textStyle}FontId`)};
        $${fontKey}FontWeight: ${this.get(`font${textStyle}Weight`)};
        ${this.get(`font${textStyle}BoldWeight`) ? `$${fontKey}FontBoldWeight: ${this.get(`font${textStyle}BoldWeight`)};` : ""}

        $${fontKey}FontLineHeight: ${this.get(`font${textStyle}LineHeight`)};
        $${fontKey}FontLetterSpacing: ${this.get(`font${textStyle}LetterSpacing`)};
        $${fontKey}FontTextTransform: ${this.get(`font${textStyle}TextTransform`)};
        $${fontKey}FontScaling: ${this.get(`font${textStyle}Scaling`)};
        $${fontKey}FontStrokeThickness: ${this.get(`font${textStyle}StrokeThickness`)};
        `;
    },

    async loadStyleSheet(cssFilePath, baseStyleSheet = null) {
        const cssText = await fetch(cssFilePath).then(response => response.text());
        return this.mergeCssIntoStylesheet(baseStyleSheet, cssText);
    },

    mergeCssIntoStylesheet(styleSheet = null, css) {
        if (_.isEmpty(css)) {
            return styleSheet ?? new StyleSheet(this, "");
        }

        if (styleSheet) {
            _.merge(styleSheet, new StyleSheet(this, css));
        } else {
            styleSheet = new StyleSheet(this, css);
        }
        return styleSheet;
    },

    sync: function() {
        //void
    }
});

const UserThemes = ReferenceCollection.extend({
    model: Theme,
    referenceRoot: "users",
    getReferenceId: function() {
        return `${this.userId}/user_themes`;
    },

    initialize() {
        this.type = "Themes";
        this.userId = app.user.id;
    },

    getThemesInWorkspace(orgId) {
        return this.filter(theme => {
            const inOrg = theme.get("orgId") == orgId;
            const isPrivate = theme.get("private");
            return inOrg && !isPrivate;
        });
    },

    saveUserTheme: async function(themeModel, name, orgId) {
        // noop
    }
});

const BuiltInThemes = Backbone.Collection.extend({
    model: Theme,

    initialize: function() {
        Object.values(themes).forEach(theme => {
            this.add(new Theme(theme, { disconnected: true, autoLoad: false }));
        });
    }
});

export {
    ShapeType, PaintStyle, ShapeColorStyle, IconStyle, ThemeStyleWeight, PREDEFINED_PALETTES,
    Theme, UserThemes, BuiltInThemes
};

